<template>
  <div class="product-page">
    <div class="product-page__wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>产品展示</el-breadcrumb-item>
        <el-breadcrumb-item v-if="activeName">{{ activeName }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="product-list-wrapper">
        <div class="left">
          <img src="@/assets/png/product-center.png" alt="NO IMAGE">
          <div class="product-type-list">
            <div v-for="type of typeList" :key="type.id">
              <div
                :class="['product-type', 'parent', activeId === type.id ? 'active' : '']"
                v-if="type.parentId === 0"
                @click="handleClick(type.id, type.typeName)"
              >
                {{ type.typeName }}
              </div>
              <div
                :class="['product-type', activeId === child.id ? 'active' : '']"
                v-for="child of getChildList(type.id)"
                :key="child.id"
                v-if="type.parentId === 0"
                @click="handleClick(child.id, child.typeName)"
              >
                 -- {{child.typeName}}
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="row">
            <div
              class="product"
              v-for="product of productList"
              :key="product.id"
            >
              <img :src="`${$host}:7001/public/${product.productPic}`" alt="NO IMAGE">
              <div class="product-name">{{ product.productName }}</div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        activeId: 0,
        activeName: '',
        typeList: [],
        allList: [],
      }
    },
    
    computed: {
      productList() {
        const { activeId, allList, typeList } = this
        return allList.filter((item) => {
          const type = typeList.find(obj => obj.id === item.typeId)
          return type && (type.id === activeId || type.parentId === activeId)
        })
      },
    },
    
    mounted() {
      this.getProductList()
      this.getTypeList()
     
    },
    
    methods: {
      getChildList(id) {
        const { typeList } = this
        return typeList.filter(item => item.parentId === id)
      },
      handleClick(activeId, activeName) {
        this.activeId = activeId
        this.activeName = activeName
      },
      getTypeList() {
        axios.get('/api/type').then(data => {
          this.typeList = data.data
          this.activeId = this.typeList[0].id
          this.activeName = this.typeList[0].typeName
        })
      },
      getProductList() {
        axios.get('/api/product').then(data => {
          this.allList = data.data
        })
      }
    }
  }
</script>

<style lang="less">
  @media screen and (max-width: 1500px) {
    .product-page {
      &__wrapper {
        width: 79% !important;
      }
    }
  }
  
  .product-page {
    background: #ffffff;
    width: 100%;
    padding: 32px 0;
  
    &__wrapper {
      margin: 0 auto;
      width: 1300px;
      
      .el-breadcrumb {
        margin-bottom: 16px;
        font-size: 18px;
      }
      
      .product-list-wrapper {
        display: flex;
        
        .left {
          width: 270px;
          border: 1px solid #d8d8d8;
          margin-right: 32px;
          height: 100%;
  
          .product-type-list {
            
            .product-type {
              padding: 16px;
              position: relative;
              cursor: pointer;
              
              &.active {
                &::after {
                  content: '';
                  position: absolute;
                  right: 0;
                  top: 10px;
                  width: 3px;
                  height: 60%;
                  background-color: #3a61a7;
                }
              }
            }
            
            .product-type.parent {
              background-color: #d7dfeb;
            }
          }
        }
        .right {
          width: calc(100% - 270px - 32px);
          
          .row {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
          }
          
          .product {
            border: 1px solid #d8d8d8;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            margin: 0 18px 16px 0;
            width: 300px;
            
            &:hover {
              box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
            }
    
            img {
              margin-bottom: 16px;
              width: 227px;
              height: 198px;
            }
    
            .product-name {
              background-color: #2256a8;
              color: #ffffff;
              padding: 16px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
</style>
